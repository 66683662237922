var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import './styles.css';
import { initializeMemeCard } from './memegen';
import { initializeStakingCard } from './staking';
import { initializeTicketsCard } from './tickets';
import { Connection, PublicKey } from '@solana/web3.js';
import { cardContent } from './cardcontent'; // Import cardContent from the new file
import songUrl from '../assets/song.mp4';
const audio = new Audio(songUrl);
audio.volume = 0.88; // Set volume to 88% of max
let isPlaying = false;
// Solana program details
export const programID = new PublicKey('RAPRxaJc8Xz2bqD3deoQLqT5noS8zsw1zWwBu95mijo');
export const raprMintAddress = new PublicKey('RAPRz9fd87y9qcBGj1VVqUbbUM6DaBggSDA58zc3N2b');
export const usdcMintAddress = new PublicKey('EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v');
export const connection = new Connection('https://mainnet.helius-rpc.com/?api-key=3e197c77-6df6-458d-8718-50b41ad3971a', 'confirmed');
const content = document.getElementById('content');
const exitButton = document.getElementById('exitButton');
const walletButton = document.getElementById('walletButton');
function fadeElement(element, fadeIn, duration, callback) {
    element.style.opacity = fadeIn ? '0' : '1';
    element.style.transition = `opacity ${duration}ms`;
    setTimeout(() => {
        element.style.opacity = fadeIn ? '1' : '0';
        if (callback)
            setTimeout(callback, duration);
    }, 50);
}
const buttonInfo = [
    { name: "STAKING", fontSize: "25px" },
    { name: "TICKETS", fontSize: "25px" },
    { name: "MEME", fontSize: "25px" }
];
function pushState(state, replace = false) {
    if (replace) {
        history.replaceState(state, '');
    }
    else {
        history.pushState(state, '');
    }
}
function showMenu() {
    const cardElement = document.querySelector('.card');
    if (cardElement) {
        fadeElement(cardElement, false, 500, () => {
            updateMenuContent();
        });
    }
    else {
        updateMenuContent();
    }
    const exitButton = document.getElementById('exitButton');
    if (exitButton) {
        exitButton.innerHTML = '<span class="button-text">HOME</span>';
    }
    const centerContainer = document.querySelector('.center-container');
    if (centerContainer) {
        centerContainer.style.display = 'flex';
        fadeElement(centerContainer, true, 500);
    }
    pushState({ action: 'showMenu' }, true);
}
function updateMenuContent() {
    content.innerHTML = `
        <div id="menu">
            ${buttonInfo.map((button, index) => `
                <button class="menuButton" style="font-size: ${button.fontSize};" data-index="${index}"><span class="button-text">${button.name}</span></button>
            `).join('')}
        </div>
    `;
    const logo = document.getElementById('logo');
    if (logo) {
        logo.style.display = 'block';
        fadeElement(logo, true, 500);
    }
    const menuElement = document.getElementById('menu');
    if (menuElement) {
        fadeElement(menuElement, true, 500);
    }
    // Attach event listeners to menu buttons
    const menuButtons = document.querySelectorAll('.menuButton');
    menuButtons.forEach(button => {
        button.addEventListener('click', function () {
            const index = parseInt(this.getAttribute('data-index') || '0', 10);
            showCard(index);
        });
    });
}
function showCard(index) {
    const card = cardContent[index];
    const menuElement = document.getElementById('menu');
    const centerContainer = document.querySelector('.center-container');
    pushState({ action: 'showCard', index: index });
    if (menuElement)
        fadeElement(menuElement, false, 500);
    if (centerContainer) {
        fadeElement(centerContainer, false, 500, () => {
            centerContainer.style.display = 'none';
            content.innerHTML = `
                <div class="card ${index === 2 ? 'meme-card' : ''}" data-index="${index}">
                    <div class="card-header">${card.title}</div>
                    <div class="card-content">
                        ${card.content}
                    </div>
                </div>
            `;
            const cardElement = document.querySelector('.card');
            if (cardElement) {
                cardElement.style.removeProperty('margin-top');
                cardElement.style.removeProperty('top');
                fadeElement(cardElement, true, 500, () => {
                    // Initialize card content based on index after fade-in
                    if (index === 0) {
                        initializeStakingCard(wallet);
                    }
                    else if (index === 1) {
                        initializeTicketsCard(wallet);
                    }
                    else if (index === 2) {
                        initializeMemeCard();
                    }
                    // If wallet is connected, update the content immediately
                    if (wallet && wallet.publicKey) {
                        if (index === 0 || index === 1) {
                            const infoElement = index === 0 ? document.getElementById('stakingInfo') : document.getElementById('ticketsInfo');
                            if (infoElement) {
                                infoElement.innerHTML = '<p>Loading...</p>';
                                updateCardContent();
                            }
                        }
                    }
                });
            }
        });
    }
    // Update exit button text
    if (exitButton) {
        exitButton.innerHTML = '<span class="button-text">HOME</span>';
    }
}
export let wallet = null;
function connectWallet() {
    return __awaiter(this, void 0, void 0, function* () {
        if (window.solana) {
            try {
                yield window.solana.connect();
                return window.solana;
            }
            catch (err) {
                console.error('Failed to connect wallet', err);
                alert('Failed to connect wallet. Please try again.');
            }
        }
        else {
            console.error('Solana wallet not found');
            alert('Solana wallet not found. Please install a Solana wallet extension.');
        }
        return null;
    });
}
// Custom event for wallet changes
export const walletChangeEvent = new Event('walletChange');
function checkLogoMenuIntersection() {
    const logo = document.getElementById('logo');
    const menu = document.getElementById('menu');
    if (!logo || !menu) {
        console.warn('Logo or menu element not found');
        return;
    }
    const logoRect = logo.getBoundingClientRect();
    const menuRect = menu.getBoundingClientRect();
    if (logoRect.bottom > menuRect.top + menuRect.height * 0.25) {
        logo.style.opacity = '1';
        logo.classList.add('scaled-downdown');
    }
    else if (logoRect.bottom > menuRect.top) {
        logo.style.opacity = '1';
        logo.classList.add('scaled-down');
    }
    else {
        logo.style.opacity = '1';
        logo.classList.remove('scaled-down');
    }
}
function createTicker(text, targetElement) {
    // Repeat the text enough times to ensure it's wider than the container
    const repeatedText = text.repeat(20); // Adjust this number as needed
    // Create two spans to create a seamless loop
    targetElement.innerHTML = `
        <span>${repeatedText}</span>
        <span>${repeatedText}</span>
    `;
}
window.addEventListener('DOMContentLoaded', () => {
    showMenu();
    // Create the top bar structure with the ticker and correct button layout
    const topBar = document.getElementById('topBar');
    if (topBar) {
        topBar.innerHTML = `
            <div id="tickerContainer">
                <div class="ticker-content"></div>
            </div>
            <div id="buttonContainer">
                <button id="exitButton"><span class="button-text">HOME</span></button>
                <button id="walletButton"><span class="button-text">CONNECT</span></button>
            </div>
        `;
        const tickerContent = topBar.querySelector('.ticker-content');
        if (tickerContent) {
            createTicker(" SPECIAL MONEY FRIENDS • ", tickerContent);
        }
        // Reinitialize the wallet button
        const walletButton = document.getElementById('walletButton');
        if (walletButton) {
            walletButton.addEventListener('click', () => __awaiter(void 0, void 0, void 0, function* () {
                if (wallet && wallet.publicKey) {
                    // Disconnect wallet
                    wallet = null;
                    walletButton.innerHTML = '<span class="button-text">CONNECT</span>';
                    // Clear the content of staking and tickets cards
                    const stakingInfo = document.getElementById('stakingInfo');
                    const ticketsInfo = document.getElementById('ticketsInfo');
                    if (stakingInfo)
                        stakingInfo.innerHTML = '<p>Please connect your wallet to view staking information.</p>';
                    if (ticketsInfo)
                        ticketsInfo.innerHTML = '<p>Please connect your wallet to view ticket information.</p>';
                }
                else {
                    wallet = yield connectWallet();
                    if (wallet && wallet.publicKey) {
                        const addressText = wallet.publicKey.toString().slice(0, 6);
                        walletButton.innerHTML = `<span class="button-text">${addressText}</span>`;
                        // Update the content of staking and tickets cards
                        updateCardContent();
                    }
                }
                // Dispatch custom event
                window.dispatchEvent(walletChangeEvent);
            }));
        }
        // Reinitialize the exit button
        const exitButton = document.getElementById('exitButton');
        if (exitButton) {
            exitButton.addEventListener('click', showMenu);
        }
    }
    // Initialize the logo click event
    const logoElement = document.getElementById('logo');
    if (logoElement) {
        logoElement.addEventListener('click', () => {
            if (isPlaying) {
                audio.pause();
                isPlaying = false;
            }
            else {
                audio.play();
                isPlaying = true;
            }
        });
    }
    // We'll call checkLogoMenuIntersection after a short delay to ensure the DOM is fully loaded
    setTimeout(checkLogoMenuIntersection, 100);
});
function updateCardContent() {
    return __awaiter(this, void 0, void 0, function* () {
        const currentCard = document.querySelector('.card');
        if (currentCard) {
            const index = parseInt(currentCard.getAttribute('data-index') || '0', 10);
            if (index === 0) {
                yield initializeStakingCard(wallet);
            }
            else if (index === 1) {
                yield initializeTicketsCard(wallet);
            }
        }
        else {
            // If no card is currently displayed, update both
            yield initializeStakingCard(wallet);
            yield initializeTicketsCard(wallet);
        }
    });
}
window.addEventListener('popstate', (event) => {
    if (event.state && event.state.action === 'showCard') {
        showCard(event.state.index);
    }
    else {
        showMenu();
    }
});
